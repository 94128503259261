<template>
  <div class="box">
    <div class="header" style="padding-top:24px;">
      <el-form
        :model="listFrom"
        ref="listFrom"
        :inline="true"
        label-position="right"
        label-width="80px"
      >
        <el-form-item label="账号" prop="phone">
          <el-input
            @input="fromListPhone"
            v-model="listFrom.phone"
            placeholder="请输入内容"
            class="input-with-select"
          ></el-input>
        </el-form-item>
        <el-form-item style="margin-left: 35px" label="类别" prop="memberLevel">
          <el-select placeholder="全部" v-model="listFrom.memberLevel" @change="changeMember">
            <!-- 选择 -->
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <br />
        <el-form-item label="机构名称" prop="companyName">
          <el-input
            @input="formlistName"
            v-model="listFrom.companyName"
            placeholder="请输入内容"
            class="input-with-select"
          ></el-input>
        </el-form-item>
        <el-button
          type="primary"
          style="margin-left:524px; width:115px;height:34px;"
          @click="resetForm('listFrom')"
        >重置</el-button>
      </el-form>
    </div>
    <div class="body">
      <el-table
        :data="userVoPageInfoList"
        border
        style="width: 100%"
        :header-cell-style="{
          background: '#BFDFF7',
          color: '#606266',
          fontSize: '12px !important',
          border: 'none',
        }"
        :row-style="{ fontSize: '12px !important', border: 'none' }"
      >
        <el-table-column type="index" align="center" label="序号"></el-table-column>
        <el-table-column prop="phone" align="center" label="账号"></el-table-column>
        <el-table-column prop="type" align="center" label="账号类别"></el-table-column>
        <el-table-column prop="status" align="center" label="状态"></el-table-column>
        <el-table-column prop="companyName" align="center" label="所属机构"></el-table-column>
        <el-table-column prop="createUser" align="center" label="创建人"></el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间"></el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <el-button type="text" @click="dialogVisible = true">查看</el-button>
          <el-button type="text" @click="dialogVisible = true">管理</el-button>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listFrom.pageNum"
        :page-sizes="[10, 15, 20, 25]"
        :page-size="listFrom.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 弹框 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <span>功能开发中~</span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      listFrom: {
        phone: "", //账号
        type: "",
        status: "",
        pageSize: "10",
        pageNum: "1",
        memberLevel: "",
        companyName: "",
      },
      options1: [
        {
          value: "V1",
          label: "L1",
        },
        {
          value: "V2",
          label: "L2",
        },
        {
          value: "V3",
          label: "L3",
        },
        {
          value: "V4",
          label: "L4",
        },
        {
          value: "V5",
          label: "L5",
        },
        {
          value: "V6",
          label: "L6",
        },
        {
          value: "V7",
          label: "L7",
        },
        {
          value: "V8",
          label: "L8",
        },
      ],
      userVoPageInfoList: [], //数据
      total: null,
    };
  },
  computed: {},
  methods: {
    // 弹框
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    // 重置
    resetForm(formName) {
      console.log(1111);
      this.$refs[formName].resetFields();
      this.getList();
    },
    // 机构名称
    formlistName() {
      let vm = this;
      clearTimeout(vm.timer); //进入删除上一个
      vm.timer = setTimeout(function () {
         vm.listFrom.pageNum = 1;
        vm.getList();
      }, 1000);
    },
    // 账号
    fromListPhone() {
      let vm = this;
      clearTimeout(vm.timer); //进入删除上一个
      vm.timer = setTimeout(function () {
        vm.getList();
      }, 1000);
    },
    // 类别
    changeMember(e) {
       this.listFrom.pageNum = 1;
      this.getList();
    },
    async getList() {
      let listFrom = this.listFrom;
      const { data: res } = await this.$http({
        method: "get",
        url: `/manager/getUserList?phone=${listFrom.phone}&memberLevel=${listFrom.memberLevel}&companyName=${listFrom.companyName}&pageSize=${listFrom.pageSize}&pageNum=${listFrom.pageNum}`,
      });
      if (res.code !== 200) return;
      console.log(res.data);
      this.userVoPageInfoList = res.data.userVoPageInfo.list;
      this.total = res.data.userVoPageInfo.total;

      console.log(this.userVoPageInfoList);
    },
    // 分页器
    handleSizeChange(e) {
      console.log(e);
      this.listFrom.pageSize = e;
      this.getList();
    },
    handleCurrentChange(e) {
      this.listFrom.pageNum = e;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style scoped lang="less">
.box {
  width: 100%;
  .header {
    width: 100%;
    height: 142px;
    background: #ffffff;
    box-shadow: 0px 0px 10px #bfdff7;
    opacity: 1;
    border-radius: 10px;
  }
  .body {
    width: 100%;
    // height: 542px;
    background: #ffffff;
    border-radius: 4px;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 22px 0 0 20px;
  }
}
/deep/.el-button--primary {
  color: #1883e3;
  background-color: #ffffff;
  border-color: #409eff;
}
</style>
